import React, { useState, useEffect } from "react";
import { Layout, Menu, theme } from "antd";
import {
  SignatureOutlined,
  CommentOutlined,
} from "@ant-design/icons";
import "./Home.scss";
import "./Home2.scss";
import "../../assets/styles/animation.scss";
import { useNavigate, Outlet } from "react-router-dom";
import Float_button from "../../components/FloatButton/Float_button";
const { Header, Content, Footer } = Layout;

const items = [
  {
    key: "/",
    label: (
      <div className="header_big">
        <div>首页</div>
        <div className="header_min">HOME</div>
      </div>
    ),
  },
  {
    key: "/products",
    label: (
      <div className="header_big">
        <div>产品中心</div>
        <div className="header_min">PRODUCTS</div>
      </div>
    ),
  },
  {
    key: "/settle",
    label: (
      <div className="header_big">
        <div>解决方案</div>
        <div className="header_min">SOLUTIONS</div>
      </div>
    ),
  },
  {
    key: "/client",
    label: (
      <div className="header_big">
        <div>客户案例</div>
        <div className="header_min">CASES</div>
      </div>
    ),
  },
  {
    key: "/news",
    label: (
      <div className="header_big">
        <div>新闻咨讯</div>
        <div className="header_min">NEWS</div>
      </div>
    ),
  },
  {
    key: "/about",
    label: (
      <div className="header_big">
        <div>关于巨川</div>
        <div className="header_min">ABOUT</div>
      </div>
    ),
  },
];

export default function Home() {
  // 返回顶部
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  //顶部导航栏
  const huidaosy = () => {
    navigate("/");
  };
  const relation = () => {
    navigate("/relation");
  };
  const home_zxlx = () => {
    navigate("/relation");
  };
  const [current, setCurrent] = useState("");
  const onClick = (e) => {
    setCurrent(e.key);
    navigate(e.key, { replace: true });
  };
  const [num, setNum] = useState(window.innerWidth > 1023)
  useEffect(() => {
    const handleResize = () => {
        if (window.innerWidth > 1023 ) {
            setNum(true)
        } else{
          setNum(false)
        }
    }
    window.addEventListener('resize', handleResize);

    return () => {
        window.removeEventListener('resize', handleResize);
    };
}, []);
  return (
    <div className="home">
      <Layout>
        {num? <div className="header_1 ">
        <Header
          className="header"
          style={{
            position: "absolute",
            paddingTop:'8rem',
            paddingBottom:'2.5rem',
            // top: 0,
            // zIndex: 14,
            width: "100%",
            display: "flex",
            alignItems: "center",
            background: "rgba(255,255,255,0.5)",
            backdropFilter: "blur(5px)",
            height: "100%",
          }}
        >
          <div className="header_logo" onClick={huidaosy}>
            <img
              className="header_logoimg"
              width={100}
              src={require('../../assets/imgs/juchuan_logon.png')
              }
              alt=""
            />
          </div>
          <div className="demo-logo" />
          <Menu
            onClick={onClick}
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={[""]}
            items={items}
            selectedKeys={[current]}
            style={{
              flex: 1,
              minWidth: 0,
              background: "rgba(255,255,255,0)",
              display: "flex",
              justifyContent: "space-around",
            }}
          />

          <div className="header_lxwm" onClick={relation}>
            <span>
              联系我们
              <SignatureOutlined />
            </span>
          </div>
        </Header>
        </div>:<Header
          className="header"
          style={{
            position: "sticky",
            // paddingTop:'10rem',
            // paddingBottom:'3rem',
            top: 0,
            zIndex: 14,
            width: "100%",
            display: "flex",
            alignItems: "center",
            background: "rgba(255,255,255,0.5)",
            backdropFilter: "blur(5px)",
            height: "100%",
          }}
        >
          <div className="header_logo" onClick={huidaosy}>
            <img
              className="header_logoimg"
              width={100}
              src={require('../../assets/imgs/juchuan_logon.png')
              }
              alt=""
            />
          </div>
          <div className="demo-logo" />
          <Menu
            onClick={onClick}
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={[""]}
            items={items}
            selectedKeys={[current]}
            style={{
              flex: 1,
              minWidth: 0,
              background: "rgba(255,255,255,0)",
              display: "flex",
              justifyContent: "space-around",
            }}
          />

          <div className="header_lxwm" onClick={relation}>
            <span>
              联系我们
              <SignatureOutlined />
            </span>
          </div>
        </Header>}
        
        <Content>
          <div
            style={{
              minHeight: 380,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            {/* 二级路由出口 */}
            <Outlet></Outlet>
          </div>
        </Content>
        <Float_button />
        <Footer
          style={{
            textAlign: "center",
            background: "#555",
          }}
        >
          {
            num?<div className="footer">
            <div className="footer_dhbig">
              <div style={{ position: 'relative' }}>
                <span className="span"></span>
                <span className="span2">

                服务热线：
                </span>
              </div>
              <div className="footer_dh">0532-80982181</div>
            </div>
            <div className="footer_lxfs">
              <div onClick={home_zxlx} style={{margin: '7px 0'}}>
                <span className="footer_ly">
                  在线留言 <CommentOutlined />
                </span>
              </div>
              <div className="yx">邮箱:qdjuchuan@163.com</div>
              <div className="diz">
                主营：二氧化氯发生器 自动化控制 水厂消毒设备 智慧水务平台
              </div>
              {/* <p>收到您的信息我们会及时与您联系</p> */}
              <div className="diz">地址：山东省青岛市西海岸新区民台路1066号 巨川智能环保水处理产业园
              </div>
            </div>

            <div className="footer_erwm">
              <span className="img">
                <img
                  src={"http://www.qdjuchuan.com/image/订阅号-20250113050736592.png"}
                  alt=""
                />
                <span>订阅号</span>
              </span>
              <span className="img">
                <img
                  src={"http://www.qdjuchuan.com/image/服务号-20250113051057472.jpg"}
                  alt=""
                />
                <span>服务号</span>
              </span>
              <span className="img">
                <img
                  src={"http://www.qdjuchuan.com/image/抖音-20250113050951833.png"}
                  alt=""
                />
                <span>抖音</span>
              </span>
            </div>
          </div>:<div className="footer">
            {/* <div className="footer_dhbig">
              <div style={{ position: 'relative' }}>
                <span className="span"></span>
                <span className="span2">

                服务热线：
                </span>
              </div>
              <div className="footer_dh">0532-80982181</div>
            </div> */}
            <div className="footer_lxfs">
              <div onClick={home_zxlx} style={{margin: '7px 0'}}>
                <span className="footer_ly">
                  在线留言 <CommentOutlined />
                </span>
              </div>
              <div className="yx">邮箱:qdjuchuan@163.com</div>
              <div className="diz">
                主营：二氧化氯发生器 自动化控制 水厂消毒设备 智慧水务平台
              </div>
              <p>收到您的信息我们会及时与您联系</p>
              {/* <div className="diz">地址：山东省青岛市西海岸新区民台路1066号 巨川智能环保水处理产业园
              </div> */}
            </div>

            <div className="footer_erwm">
              <span className="img">
                <img
                  src={"http://www.qdjuchuan.com/image/订阅号-20250113050736592.png"}
                  alt=""
                />
                <span>订阅号</span>
              </span>
              <span className="img">
                <img
                  src={"http://www.qdjuchuan.com/image/服务号-20250113051057472.jpg"}
                  alt=""
                />
                <span>服务号</span>
              </span>
              <span className="img">
                <img
                  src={"http://www.qdjuchuan.com/image/抖音-20250113050951833.png"}
                  alt=""
                />
                <span>抖音</span>
              </span>
            </div>
          </div>
          }
          
          <div className="footer_gsba">
            青岛巨川 ©{new Date().getFullYear()} 青岛巨川环保科技有限公司
            版权所有 备案号：鲁ICP备17023476号-1
          </div>
        </Footer>
      </Layout>
    </div>
  );
}
