import React, { useState, useEffect } from "react";
import Banner from "../../components/banner/banner";
import { Link } from "react-router-dom";
import { Breadcrumb, Tabs } from "antd";
import Swaiper1 from "./swiper1";
import Swaiper2 from "./swiper2";
import Swaiper3 from "./swiper3";
import "./About.scss";
import "./About2.scss";
import useInView from '../../components/hook/hook'

export default function About() {
  const text = "关于巨川";
  const text_yw = "SOLUTION";
  const imgUrl =
    "http://www.qdjuchuan.com/image/about_backgroud1-2024031608204050.jpg";
  // 选项卡
  const TabsChange = (key) => {
    // console.log(key);
  };

  const zjlist = [
    {
      className: "tb_item tb_after",
      className2: "tb_img tb_img1",
      title: "环境工程专业",
    },
    {
      className: "tb_item tb_after",
      className2: "tb_img tb_img2",
      title: "给排水专业",
    },
    {
      className: "tb_item tb_after",
      className2: "tb_img tb_img3",
      title: "自动化专业",
    },
    {
      className: "tb_item tb_after",
      className2: "tb_img tb_img4",
      title: "计算机技术专业",
    },
    {
      className: "tb_item tb_after",
      className2: "tb_img tb_img5",
      title: "电气工程专业",
    },
    {
      className: "tb_item ",
      className2: "tb_img tb_img6",
      title: "机械工程专业",
    },
  ];

  const Tabslist = [
    {
      key: "1",
      label: "发明专利",
      children: <Swaiper1 />,
    },
    {
      key: "2",
      label: "软件著作权",
      children: <Swaiper2 />,
    },
    {
      key: "3",
      label: "荣誉资质",
      children: <Swaiper3 />,
    },
  ];

  const breadcrumb = [
    { title: <Link to="/">首页</Link> },
    { title: <Link to="/about">关于巨川</Link> },
  ];
    //监听
    const [targetRef, inView] = useInView();
    const [targetRef1, inView1] = useInView();
    const [targetRef2, inView2] = useInView();
    const [targetRef3, inView3] = useInView();
    const [targetRef4, inView4] = useInView();
    const [targetRef5, inView5] = useInView();

  // 返回顶部
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="about_mian">
      <Banner data={{ text, text_yw, imgUrl }} />
      <div className="daoh">
        <div className="daoh_content">
          <div ref={targetRef} className={inView ? "gsjs animationleft":"gsjs"}>公司介绍</div>
          <div ref={targetRef} className={inView ? "mbx animationright":"mbx"}>
            <img src={require("../../assets/imgs/client/pos_ico.png")} alt="" />
            <span>您当前位置： </span>
            <Breadcrumb items={breadcrumb} />
          </div>
        </div>
      </div>
      {/* 关于公司 */}
      <div className="about_big">
        <div className="about_content">
          <div className="gs">
            <div ref={targetRef1} className={inView1 ? "item left animationleft":"item left"}>
              <img
                className="img"
                src={require("../../assets/imgs/about/1.jpg")}
                alt=""
              />
            </div>
            <div ref={targetRef1} className={inView1 ? "item right animationright":"item right"}>
              <h1>青岛巨川环保科技有限公司</h1>
              <h4 className="jyll">掌握核心科技 &nbsp;&nbsp;打造行业品牌</h4>
              <div className="text_p">
                <p className="js">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;公司成立于2008年，坐落在被誉为“品牌之都”的海滨城市—青岛，
                  是一家专业从事水处理设备的研发与生产，智慧系统的开发与建设，环保工程的设计、
                  建设与运营的综合性服务制造商。为客户提供自动化控制系统、智慧水务平台、水质预警监测系统、加氯加药系统
                  、水中特种离子去除工艺等多方位、专业化解决方案。
                </p>
                <br />
                <p className="js">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;公司拥有自主知识产权、多项专利、计算机软著以及电子与智能化工程、水污染治理等专项资质，
                  并独立研发生产二氧化氯发生器、全自动加药设备、生物除臭设备、应用膜处理设备、活性炭投加装置、
                  离子交换设备、污水处理一体化和中水回用设备等环保水处理设备，广泛应用于饮用水、污水、
                  化工循环水等污染防治领域，被全国化工标准物质委员会授予为“全国二氧化氯专业委员会副理事长单位”、
                  “全国二氧化氯研发基地”，是集科研开发、设计、生产、销售和售后服务于一体的高新技术企业。
                </p>
                <br />
                <p className="js">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;公司实行集团化管理，拥有专业的人才队伍、先进的技术、精细化的生产流程和高品质的产品，为客户
                  提供可靠、优质、满意的服务。
                </p>
                <br />
                <p className="js">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;公司已通过ISO9001质量管理体系认证、ISO45001职业健康安全管理体系认证、ISO14001环境管理体系
                  认证，荣获AAA级诚信经营示范单位、AAA级重合同守信用单位、AAA级信用单位，公司一直秉承“专业化
                  、标准化、品牌化、集团化”的发展战略。
                </p>
              </div>
            </div>
          </div>
          <div ref={targetRef2} className={inView2 ? "zhizhi animationmin":"zhizhi"}>
            <div className="zhizhi_item">
              <div className="ys">10+</div>
              <div className="ys2">
                <span className="tb1"></span>专利证书
              </div>
            </div>
            <div className="zhizhi_item">
              <div className="ys">10+</div>
              <div className="ys2">
                <span className="tb2"></span>软件著作权
              </div>
            </div>
            <div className="zhizhi_item">
              <div className="ys">30%</div>
              <div className="ys2">
                <span className="tb3"></span>技术人员占比
              </div>
            </div>
            <div className="zhizhi_item">
              <div className="ys">2000+</div>
              <div className="ys2">
                <span className="tb4"></span>环保水处理项目
              </div>
            </div>
          </div>
          <div className="jytd">
            <div ref={targetRef3} className={inView3 ? "item left animationup":"item left"}>
              <div className="jytd_text">
                <h1 className="h1">我们的精英团队</h1>
                <div className="lzzb">
                  质量重于产量，服务重于业务，突出巨川信誉。
                </div>
                <div className="jytd_zb">
                  <div className="item1">
                    <div className="num">34</div>
                    <div className="text">平均年龄</div>
                  </div>
                  <div className="item1">
                    <div className="num">32%</div>
                    <div className="text">研发与技术人员</div>
                  </div>
                  <div className="item1">
                    <div className="num">81%</div>
                    <div className="text">大学以上学历</div>
                  </div>
                  <div className="item1">
                    <div className="num">8</div>
                    <div className="text">领域专家智囊团</div>
                  </div>
                </div>
              </div>
            </div>
            <div ref={targetRef3} className={inView3 ? "item right animationup":"item right"}>
              <img
                src={
                  require("../../assets/imgs/about/微信图片_20230519190246.jpg")
                }
                alt=""
              />
            </div>
            <div ref={targetRef4} className={inView4 ? "tb animationleft":"tb"}>
              {zjlist.map((item) => {
                return (
                  <div className={item.className}>
                    <div className={item.className2}></div>
                    <div className="tb_tx">{item.title}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="about_zs">
          <h1>荣誉资质</h1>
          <div className="title2">
            <span className="ico"></span>ABOUT US
          </div>
          <div className="xxk">
            <Tabs
              defaultActiveKey="1"
              centered
              items={Tabslist}
              onChange={TabsChange}
            />
          </div>
        </div>
        {/* 企业文化 */}
        <div ref={targetRef5} className={inView5 ? "about_qywh animationup":"about_qywh"}>
          <h1>企业文化</h1>
          <div className="title2">
            <span className="ico"></span>CULTURE
          </div>
          <div className="qywh_content">
            <div className="content">
              <div className="qywh_item qywh_item1">
                <div className="backgrout_mc backgrout_mc1"></div>
                <div className="text">
                  <div className="ioc ioc1"></div>
                  <div className="bt">巨川愿景</div>
                  <p>让每一位员工心情愉悦地体现价值、创造价值。</p>
                </div>
              </div>
              <div className="qywh_item qywh_item2">
                <div className="backgrout_mc backgrout_mc2"></div>
                <div className="text">
                  <div className="ioc ioc2"></div>
                  <div className="bt">经营理念</div>
                  <p>
                    以人才为根本，以质量求生存。以创新求发展，以优质的售后服务做后盾。
                  </p>
                </div>
              </div>
              <div className="qywh_item qywh_item3">
                <div className="backgrout_mc backgrout_mc3"></div>
                <div className="text">
                  <div className="ioc ioc3"></div>
                  <div className="bt">核心价值观</div>
                  <p>诚实守信、廉洁自律、团队合作、公平公正。</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
