import React from "react";
import { LikeOutlined, CustomerServiceOutlined, CommentOutlined, TikTokOutlined, SendOutlined,PhoneOutlined  } from "@ant-design/icons";
import { FloatButton } from "antd";
const Float_button = () => {
  const xtys_dh = {
    color: '#2a7bdd',
  };
  const xtys_img = {
    with: '7vmin',
    height: '7vmin',
  }
  return (
    <div className="float_btn">
      <>
        <FloatButton.Group
          // trigger="hover"
          size="large"
          closeIcon={<LikeOutlined />}
          type="default"
          style={{
            right: 30,
            size: 30
          }}
          icon={<SendOutlined />}
        >
          <FloatButton icon={<PhoneOutlined />} tooltip={<div><div className="xtys_bt">服务热线：</div><div style={xtys_dh}>0532-80982181</div></div>} />
          <FloatButton icon={<CommentOutlined />} tooltip={<div><div className="xtys_bt">订阅号：</div><div><img style={xtys_img} src={'http://www.qdjuchuan.com/image/订阅号-20250113050736592.png'} alt="" /></div></div>} />
          <FloatButton icon={<TikTokOutlined />} tooltip={<div><div className="xtys_bt">抖音号：</div><div><img style={xtys_img} src={'http://www.qdjuchuan.com/image/抖音-20250113050951833.png'} alt="" /></div></div>} />
          <FloatButton.BackTop duration='1000' />
        </FloatButton.Group>
      </>
    </div>
  )
};
export default Float_button;